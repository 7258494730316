import React, { useContext, useState, useEffect } from "react"
import styled from "styled-components"
import { addItem, getCart, updateAllItems } from "../../services/cart/cart"
import { CartContext } from "../../context/CartContext"
import OnClickButton from "src/components/Core/OnClickButton"
import breakpoints from "src/styles/breakpoints"

const ButtonContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    width: 60%;
    button {
      width: 100%;
      :disabled {
        opacity: 0.6;
      }
    }
  }
`

const Purchase = ({
  productId,
  options,
  quantity,
  disabled,
  error,
  setIsPurchaseOpen,
}) => {
  const [cart, setCart] = useContext(CartContext)
  const [purchasing, setPurchasing] = useState(false)
  const [showError, setShowError] = useState(false)

  const purchase = async (e) => {
    e.preventDefault()

    setPurchasing(true)

    await addItem(productId, quantity, options)

    const cartData = await getCart()

    const giftCardItems = cartData.items
      .filter((item) => item.product.slug === "giftcard")
      .map((item) => {
        const { product_id, options, quantity } = item
        return { product_id, quantity, options }
      })

    const newCardData = await updateAllItems(giftCardItems)

    setCart(newCardData)
    setIsPurchaseOpen(true)
    setPurchasing(false)
  }

  return (
    <ButtonContainer>
      <OnClickButton
        onClick={(e) => purchase(e)}
        blue
        disabled={disabled || error}
      >
        {purchasing ? "Purchasing..." : "Purchase"}
      </OnClickButton>
    </ButtonContainer>
  )
}

export default Purchase
