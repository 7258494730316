import React from "react"
import styled from "styled-components"
import Text from "../Blocks/Text"
import CtaButton from "../Core/CtaButton"
import ProductInformation from "./ProductInformation"
import DynamicBlocks from "./DynamicBlocks"

const Block = styled.div``

const Heading3 = styled.h3``

const Heading4 = styled.h4``

const Paragraph = styled.p``

const Button = styled(CtaButton)``

const Contentful = (props) => {
  const {
    aboutTheContainers,
    restaurant,
    chefsRecommendations,
    ingredients,
    productDetails,
    blocks,
  } = props.data

  return (
    <>
      {productDetails && <ProductInformation chefsRecommendations={chefsRecommendations} ingredients={ingredients} informations={productDetails} />}
      {blocks && blocks.map((block, index) => {
        return <DynamicBlocks block={block} key={index} />
      })}
    </>
  )
}

export default Contentful
