import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ELink from "./ExternalLink"
import { ctaButtonStyle } from "./Styles"

const StyledButton = styled.button`
  ${ctaButtonStyle};
  padding: 0.35em .7em;
  
`

const NewOnClickButton = ({ children, ...rest }) => {
  return <StyledButton {...rest}>{children}</StyledButton>
}

export default NewOnClickButton
