import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import Text from "src/components/Product/Text"
import ExternalLink from "../../Core/ExternalLink"
import { Row, Col } from "styled-bootstrap-grid"
import colors from "../../../styles/colors"
import fonts from "../../../styles/fonts"
import breakpoints from "../../../styles/breakpoints"

const BlockContainer = styled.div`
  padding: 17px 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 5vw;
  }
`

const StyledCol = styled(Col)`
  margin-top: calc(-10% - 10%);

  @media (min-width: ${breakpoints.md}) {
    margin-top: 0;
  }
`

const StyledImage = styled(GatsbyImage)`
  max-height: 400px;
  @media (min-width: ${breakpoints.md}) {
    max-height: unset;
    width: 120%;
    margin-left: -20%;
  }
`

const Details = styled.div`
  background: ${colors.pink};
  padding: 10%;
  color: ${colors.lightGrey};
  font-size: 1rem;
`

const Heading = styled.h2`
  font-size: calc(3.6vw + 1rem);
  color: ${colors.white};
  margin-bottom: 12px;
  line-height: 1;
`

const Address = styled.p`
  ${fonts.workSansBold};
  font-size: calc(0.24vw + 1rem);
  margin-bottom: 12px;
`

const Description = styled.div`
  margin-bottom: 12px;
`
const LinkContainer = styled.div`
  margin-bottom: 8px;
  :last-of-type {
    margin-bottom: 0;
  }
`
const Link = styled(ExternalLink)`
  color: ${colors.lightBlue};
  display: inline-block;

`

const RestaurantBlock = ({ address, description, links, name, photo }) => {
  return (
    <BlockContainer>
      <Row mdAlignItems="center">
        <Col
          xs={11}
          sm={11}
          xsOffset={1}
          smOffset={1}
          md={6}
          mdOffset={0}
          mdOrder={"last"}
        >
          <StyledImage
            image={photo && photo.gatsbyImageData}
            alt={(!photo || photo.description === "") ? name : photo.description}
          />
        </Col>
        <StyledCol xs={11} sm={11} md={6}>
          <Details>
            <Heading>{name}</Heading>
            {address && <Address>{address}</Address>}
            <Description>
              <Text content={description} />
            </Description>
            {links && links.map((link, index) => {
              return (
                <LinkContainer>
                  <Link href={link.url} key={index}>
                    {link.label}
                  </Link>
                </LinkContainer>
              )
            })}
          </Details>
        </StyledCol>
      </Row>
    </BlockContainer>
  )
}

export default RestaurantBlock
