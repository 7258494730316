import React from 'react'
import styled, { keyframes }  from 'styled-components'

const spin = keyframes`
    0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
    background-color: transparent;
    border-radius: 1000px;
    display: block;
    border: ${props => props.light ? "2px solid rgba(255, 255, 255, 0.7)" : "2px solid rgba(0, 0, 0, 0.75)"};
    border-top-color: ${props => props.light ? "rgba(255, 255, 255, 0.15)" : "rgba(0, 0, 0, 0.08)"};
    border-left-color: ${props => props.light ? "rgba(255, 255, 255, 0.15)" : "rgba(0, 0, 0, 0.08)"};
    height: 100%;
    width: 100%;
    animation-name: ${spin};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
`


const Loader = ({lightColor}) => {
    return (
      <Spinner light={lightColor}></Spinner>
    )
  }

export default Loader