import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import LightArrow from "../../resources/img/svg/arrow-right-light.svg"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"

const Container = styled.div`
  margin-bottom: 1.5rem;
`

const SelectBlock = styled.div`
  margin-bottom: 0.5rem;
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.79rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 0.025em;
  color: #8c8984;
`

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-width: 15ch;
  cursor: pointer;
  ${fonts.openSansRegular};
  color: #1c1a17;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #d9d5d0;
  background-color: #fffefc;
  
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;

  select:focus + span, select:active + span {
    opacity: 1;
    border-radius:  0.25rem;
    outline: 2px solid #3e3e3e;
    outline-offset: 0;
  }

  select:focus {
    outline: none;
  }

  // Custom arrow
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 14px;
    width: 7px;
    height: 14px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-image: ${`url(${LightArrow})`};
    transform: rotate(90deg) translate(-70%);
  }

  /* select:focus + span {
   position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: none;
    border-radius: inherit;
  } */
`

const StyledSelect = styled.select`
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0.75rem 1rem;
  width: 100%;
  ${fonts.openSansRegular};
  color: #1c1a17;
  font-size: 1rem;
  line-height: 1.5;
  cursor: inherit;
  z-index: 1;
  &::-ms-expand {
    display: none;
  }
`

const Focus = styled.span`
  display: block;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  border-radius: 0.25rem;
`

const Error = styled.span`
  color: #e61c1c;
  font-size: 0.889rem;
  line-height: 1.5;
  ${fonts.openSansRegular};
`

const SelectField = ({
  options,
  name,
  handleChange,
  data,
  placeholder,
  label,
  error,
  reset,
  setReset,
}) => {
  const [value, setValue] = useState(data[name])
  useEffect(() => {
    handleChange(name, value)
  }, [value])

  useEffect(() => {
    if (reset === true) {
      setValue(data[name])
      setReset(false)
    }
  }, [reset])

  return (
    <Container>
      <SelectBlock>
        {label && <Label>{label}</Label>}
        <SelectWrapper>
          <StyledSelect
            name={name}
            onChange={(e) => setValue(e.target.value)}
            defaultValue={value}
          >
            <option value={""} disabled>
              {placeholder}
            </option>
            {options.map((option, index) => {
              return (
                <option key={index} value={option["value"]}>
                  {option["name"]}
                </option>
              )
            })}
          </StyledSelect>
          <Focus></Focus>
        </SelectWrapper>
      </SelectBlock>
      {error && <Error>{error}</Error>}
    </Container>
  )
}

SelectField.propTypes = {
  options: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  reset: PropTypes.bool,
  setReset: PropTypes.func,
}

export default SelectField
