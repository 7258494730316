import React from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"
import colors from "../../../styles/colors"
import Text from "../../Blocks/Text"
import CtaButton from "../../Core/CtaButton"
import { Row, Col } from "styled-bootstrap-grid"

const BlockContainer = styled.div`
  border-top: 1px solid ${colors.royalBlue};
  padding: 25px 0;
`

const Heading = styled.h2`
  color: ${colors.royalBlue};
  margin-bottom: 34px;
  font-size: calc(0.6vw + 1rem);
`

const ContentCol = styled(Col)`
  margin-bottom: 34px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
  }
`

const ButtonCol = styled(Col)`
  display: flex;
  justify-content: center;
`

const StyledRow = styled(Row)`
  text-align: center;
  padding: 17px 0;
`

const TextBlock = ({ heading, content, ctaLink, ctaLabel }) => {
  return (
    <BlockContainer>
      <StyledRow justifyContent="center" alignItems="center">
        <Col>{heading && <Heading>{heading}</Heading>}</Col>
        {content && (
          <ContentCol md={ctaLabel && ctaLink ? 6 : 12}>
            <Text content={content} />
          </ContentCol>
        )}
        {ctaLabel && ctaLink && (
          <ButtonCol md={content ? 6 : 12}>
            <CtaButton label={ctaLabel} url={ctaLink} blue />
          </ButtonCol>
        )}
      </StyledRow>
    </BlockContainer>
  )
}

export default TextBlock
