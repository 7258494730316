import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import { ctaButtonStyle } from "../Core/Styles"

export const inputStyle = `
  width: 100%;
  padding: 12px;
  margin: 6px 0 4px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  font-size: 12px;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 2px;
  :focus {
    outline: 1px solid ${colors.midGrey};
    outline-offset: -1px;
  }
`

export const submitButtonStyle = `
  ${ctaButtonStyle({ blue: true })}
`
