/* global fbq */
import React, { useContext, useState } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Gallery from "../components/Product/Gallery"
import Contentful from "../components/Product/Contentful"
import ProductDetails from "../components/Product/ProductDetails"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import styled from "styled-components"
import ELink from "../components/Core/ExternalLink"
import { Link } from "gatsby"
import {
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "src/components/Layout/Layout"
import breakpoints from "../styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import { addItem, getCart } from "../services/cart/cart"
import { CartContext } from "../context/CartContext"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import AddToCart from "src/components/Product/AddToCart"
import axios from "axios"
import { useUserContext } from "../context/UserContext"
import { getUser } from "../services/user/user"

// import Image from "../components/Blocks/Image"

const CrossSellHeader = styled.h4`
  line-height: 100%;
  font-weight: 900;
  color: ${colors.royalBlue};
`

const CrossSellRestaurantHeader = styled.h3`
  line-height: 100%;
  font-weight: 900;
  color: ${colors.pink};
`

const CartButton2 = styled(OutboundLink)`
  padding: 12px;
  margin: 4px 4px 30px 10px;
  border-color: ${colors.royalBlue};
  color: ${colors.royalBlue};
  border-radius: 300px;
  background-color: white;
  border: solid;
  border-width: 2px;
  text-decoration: none;
  font-size: 0.8em;
  width: 75px;
  font: ${fonts.workSansBold};

  :hover {
    background: ${colors.royalBlue};
    color: ${colors.white};
  }
`

const OptionName = styled.div`
  font-size: calc((0.9 - 1) * 1.2vh + 1rem);
  text-transform: uppercase;
  color: ${colors.black};
  margin-bottom: 0.5em;
  margin-top: 2em;
`

const Section = styled.div`
  margin-bottom: 40px;
`

const QuantityInput = styled.input`
  ${fonts.workSansRegular};
  border: 1px solid ${colors.midGrey};
  padding: 24px;
  outline: none;
  background: transparent;
  text-align: center;
  font-size: 1rem;
  width: 90px;
`

const CartLink = styled(Link)`
  padding: 12px;
  margin: 4px 4px 30px 10px;
  border-color: ${colors.royalBlue};
  color: ${colors.royalBlue};
  border-radius: 300px;
  background-color: white;
  border: solid;
  border-width: 2px;
  text-decoration: none;
  font-size: 0.8em;
  width: 75px;
  font: ${fonts.workSansBold};

  :hover {
    background: ${colors.royalBlue};
    color: ${colors.white};
  }
`

const CartButton = styled.button`
  padding: 12px;
  font-size: 0.8em;
  font-weight: 900;
  cursor: pointer;
  margin: 4px 4px 30px 10px;
  border-color: ${colors.royalBlue};
  color: ${colors.royalBlue};
  border-radius: 300px;
  background-color: white;
  border: solid;
  border-width: 2px;
  text-decoration: none;
  font-size: 0.8em;
  ${"" /* width: 75px; */}
  font-family: ${fonts.workSansBold};

  :hover {
    background: ${colors.royalBlue};
    color: ${colors.white};
  }
`

const CartButtonCheckout = styled(ELink)`
  padding: 12px;

  margin: 4px 4px 30px 10px;
  border-color: ${colors.royalBlue};
  color: ${colors.royalBlue};
  border-radius: 300px;
  background-color: white;
  border: solid;
  border-width: 2px;
  text-decoration: none;
  font-size: 0.8em;
  font-family: "Work Sans";
  width: auto;
  font: ${fonts.workSansBold};
  margin-bottom: 10px;
  :hover {
    background: ${colors.royalBlue};
    color: ${colors.white};
  }
`

const ButtonContainer = styled.div`
  height: 25px;
  padding-top: 10px;
  @media (min-width: ${breakpoints.md}) {
    button {
      :disabled {
        opacity: 0.6;
      }
    }
  }
`

const PageContainer = styled.div`
  max-width: ${breakpoints.max};
  margin: auto;
  padding: ${`calc(${MOBILE_HEADER_HEIGHT}px + 5vw)`} 6vw 5vw;

  @media (min-width: ${breakpoints.md}) {
    padding: ${`calc(${DESKTOP_HEADER_HEIGHT}px + 5vw)`} 5vw 5vw;
  }
`

const Image = styled.img`
  width: 96px;
  height: 96px;
  object-fit: cover;
`

const CrossSellImage = styled.img`
  width: 24vw;
  height: 24vw;
  max-width: 260px;
  max-height: 260px;
  object-fit: cover;
  @media (max-width: ${breakpoints.md}) {
    width: 260px;
    height: 260px;
    object-fit: cover;
  }
`

const CartPreview = styled.div`
  position: fixed;

  top: ${`calc(${DESKTOP_HEADER_HEIGHT}px + 5vw)`};
  z-index: 1;
  height: 250px;
  background: white;
  border-radius: 50px;
  ${"" /* border-color: ${colors.royalBlue}; */}
  border: solid ${colors.royalBlue};

  left: 50%;
  margin-left: calc(90vw / -2);
  right: 0px;

  @media (min-width: ${breakpoints.lg}) {
    width: 400px;
    right: 10px;
    margin-left: auto;
    left: auto;
  }

  @media (max-width: ${breakpoints.lg}) {
    width: 50vw;
    right: 10px;
    margin-left: auto;
    left: auto;
  }
  @media (max-width: ${breakpoints.md}) {
    width: 90vw;
    right: 10px;
    margin-left: auto;
    left: auto;
  }
`

const CartPreviewTop = styled.div`
  width: 100%;
  height: 50px;
  padding: 12px;
  border-bottom: 3px solid ${colors.royalBlue};
  ${"" /* border: */}
  ${"" /* border-color: ${colors.lightGray}; */}
`

const CartLineItem = styled.div`
  width: 100%;
  padding: 12px;
  height: 250px;
`

const CartLineItemTitle = styled.span`
  position: absolute;
  padding-left: 10px;
`

const CartPreviewX = styled.div`
  position: absolute;
  right: 15px;
  top: 14px;
`

const StyledCol = styled(Col)`
  padding-top: 25px;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 0;
  }
`

const RateProductDiv = styled.div``
const StyledLink = styled(ELink)`
  margin: 0 0.5rem;
  text-decoration: 0;
  display: inline-block;
  color: #1c1a17;
  font-size: 1rem;
  line-height: 1.5;
  ${fonts.openSansRegular};

  @media (min-width: ${breakpoints.md}) {
    ${fonts.openSansMedium};
    font-weight: ${(props) => (props.active ? "700" : "600")};
    padding: 1rem 0 1rem 0.75rem;
    display: block;
    transition-property: all 200ms cubic-bezier(0, 0, 0.2, 1);
    background: ${(props) => (props.active ? "#F2EFEB" : "transparent")};
    margin: 0;
    &:hover {
      background: #f2efeb;
      color: ${colors.royalBlue};
    }
  }
`

const ProductTemplate = (props) => {
  const { location, data } = props
  const { user, setUser } = useUserContext()

  const [isAdding, setIsAdding] = useState(false)
  const [lastAdded, setLastAdded] = useState(data.product.product_id)
  const [crossSellQuantity, setCrossSellQuantity] = useState(1)
  const path = location.pathname
  var product = data.product
  const variants = data.variants

  let cross_sells
  let cross_sell_sold_out = false
  if (data.crossSwellProduct) {
    cross_sells = data.crossSwellProduct
    if (cross_sells.stock_status === "out_of_stock") {
      cross_sell_sold_out = true
    }
  }

  const name = product?.name
  const meta_description = product?.meta_description
  const images = product?.images
  var title
  let mainProductImage

  // const [title, setTitle] = useState(name);

  if (images && images.length > 0) {
    mainProductImage = images[0].file.url
  }

  let sharingImage = false
  if (data.contentfulProduct?.socialMediaImage) {
    sharingImage = data.contentfulProduct.socialMediaImage.file.url
  }
  if (data.contentfulProduct?.title) {
    product.name = data.contentfulProduct?.title
  }
  let dishRatingLink
  if (data.contentfulProduct?.dishRatingLink) {
    dishRatingLink = data.contentfulProduct.dishRatingLink
  }
  if (data.contentfulProduct) product.dishRatingLink = dishRatingLink ?? null

  if (data.contentfulProduct?.restaurant) {
    product.restaurant = data.contentfulProduct?.restaurant
  }
  const popCart = (productId, quantity, options) => {
    // window.alert( name +  " ("+JSON.stringify(options.Variants) +")")
    if (!checkoutURL) {
      setCheckoutURL(getCart()?.checkout_url)
    }
    setLastAdded(productId)
    try {
      document.getElementById("cartPreview").style.display = "block"
    } catch (e) {
      //nothing
    }

    if (product.product_id === productId) {
      document.getElementById("cartLineItemName").innerHTML =
        name +
        (options && options.Variants ? " (" + options.Variants + ")" : "")
    } else {
      document.getElementById("cartLineItemName").innerHTML = cross_sells.name
    }

    document.getElementById("cartPreviewTop").innerHTML =
      "✔️ Added " + quantity + " item(s) to cart"

    // setTimeout(() => {

    //   document.getElementById("cartPreview").style.display = "none"
    // }, 5000)
  }

  const closeCart = () => {
    document.getElementById("cartPreview").style.display = "none"
  }

  const goToCheckout = async (e) => {
    const cart = getCart();
    let checkoutUrl = "https://eatigc.swell.store/checkout/" + cart.checkout_id;
    let urlWithClientId = checkoutUrl;

    setTimeout(() => {
      navigater(checkoutUrl);
      window.location.href= checkoutUrl;
    }, 2000); //backup option!

    try {
      const gtagData = constructGtagData(cart);
      const oldGtagData = constructOldGtagData(cart);
      sendGtagEvents(gtagData, oldGtagData);
  
      const items = cart.items;
      const totalValue = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
      const formattedValue = parseFloat(totalValue.toFixed(2));
      const eventId = generateEventId();
  
      sendFbqEvents(formattedValue, items, eventId);
  
      const conversionData = constructConversionData(formattedValue, items, eventId);
      await sendConversionData(conversionData);
  
      const clientId = await getClientId();
      urlWithClientId = `/checkout/${cart.checkout_id}/shipping?_ga=${clientId}`;
      
      navigater(urlWithClientId ?? checkoutUrl);
    } catch (e) {
      console.error(e);
      navigater(checkoutUrl);
    } finally {
      navigater(urlWithClientId ?? checkoutUrl);
    }
    setTimeout(() => {
      window.location.href = urlWithClientId ?? checkoutUrl;
  }, 1000); // Delay by 1 second to give navigater a chance to work
    //this is a backup in case the navigater doesn't work

  }
  const calculateTotalValue = (items) => {
    return items.reduce((acc, item) => acc + item.price * item.quantity, 0)
  }

  const sendConversionData = async (conversionData) => {
    try {
      const conversionURL = "https://eo4h5c5cv0xdqc2.m.pipedream.net"
      const conversionResponse = await axios.post(conversionURL, conversionData)
      console.log(conversionResponse)
      console.log("meta conversion data sent")
    } catch (e) {
      console.error("Error sending meta conversion data: " + e)
    }
  }
  function navigater(url) {
    var link = document.createElement("a")
    link.href = url
    link.style.display = "none"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const generateEventId = () => {
    return Math.floor(Math.random() * 1e16)
  }

  const constructGtagData = (cart) => {
    const { items } = cart

    return {
      items: items.map((item) => {
        const { product, price, quantity, variant_id, variant } = item
        const { name } = product
        const itemName = variant_id ? `${name} ${variant.name}` : name

        return {
          item_id: item.variant_id ?? item.product_id,
          item_name: itemName,
          price,
          quantity,
        }
      }),
      currency: "USD",
      value: cart.grand_total,
      send_to: "G-LZ50YRTV2Y",
    }
  }

  const constructOldGtagData = (cart) => {
    const { items } = cart

    return {
      items: items.map((item) => {
        const { product, price, quantity, variant_id, variant } = item
        const { name } = product
        const itemName = variant_id ? `${name} ${variant.name}` : name
        const sku = variant_id ? variant.sku : product.sku

        return {
          id: sku,
          name: itemName,
          price,
          quantity,
        }
      }),
      send_to: "UA-185335499-1",
    }
  }

  const sendGtagEvents = (gtagData, oldGtagData) => {
    if (typeof window.gtag === "undefined") {
      console.log("gtag not defined")
      return
    }

    if (user) {
      window.gtag("set", "user_id", user.id)
    }

    window.gtag("event", "begin_checkout", oldGtagData)
    window.gtag("event", "begin_checkout", gtagData)
  }

  const sendFbqEvents = (formattedValue, items, eventId) => {
    if (typeof fbq !== "function") {
      console.log("fbq not defined")
      return
    }

    fbq(
      "track",
      "InitiateCheckout",
      {
        value: formattedValue,
        currency: "USD",
        content_type: "product",
        num_items: items.length,
        contents: items.map((item) => ({
          id: item.variant_id ?? item.product_id,
          quantity: item.quantity,
        })),
      },
      { eventID: eventId }
    )
  }

  const constructConversionData = (formattedValue, items, eventId) => {
    const event_source_url = window.location.href

    const conversionData = {
      event_source_url,
      event_id: eventId,
      event_name: "InitiateCheckout",
      contents: items.map((item) => ({
        id: item.variant_id ?? item.product_id,
        quantity: item.quantity,
      })),
      value: formattedValue,
      currency: "USD",
    }

    if (user !== null) {
      conversionData.first_name = user.first_name
      conversionData.last_name = user.last_name
      conversionData.email = user.email

      if (user.shipping) {
        conversionData.phone = user.shipping.phone
        conversionData.zip = user.shipping.zip
      }
    }

    return conversionData
  }

  const getClientId = () => {
    return new Promise((resolve) => {
      if (typeof window.gtag === "undefined") {
        console.log("gtag not defined")
        resolve("")
        return
      }

      window.gtag("get", "UA-185335499-1", "client_id", (clientId) => {
        resolve(clientId)
      })
    })
  }

  const [checkoutURL, setCheckoutURL] = useState(getCart()?.checkout_url)
  let cross_sells_restaurant
  let cross_sells_dish
  try {
    cross_sells_restaurant = cross_sells.name.split("-")[0].trim()
    cross_sells_dish = cross_sells.name.split("-")[1].trim()
  } catch (e) {
    cross_sells_dish = null
    cross_sells_restaurant = null
  }

  return (
    <Layout dark bg={colors.lightGrey}>
      <Seo
        title={name}
        description={meta_description}
        image={mainProductImage}
        path={path}
        // image={sharingImage}
      />
      <CartPreview id={"cartPreview"} style={{ display: "none" }}>
        <CartPreviewTop>
          <span id="cartPreviewTop">✔️ Added 1 item(s) to cart</span>

          <CartPreviewX onClick={closeCart}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmln="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              focusable="false"
              width="1em"
              height="1em"
              style={{
                "vertical-align": "-0.125em",
                "-ms-transform": "rotate(360deg)",
                "-webkit-transform": "rotate(360deg)",
                transform: "rotate(360deg)",
              }}
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
              data-v-a7668792=""
              class="iconify icon:uil:times w-6 h-6 base"
              data-icon="uil:times"
            >
              <path
                fill="currentColor"
                d="m13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z"
              ></path>
            </svg>
          </CartPreviewX>
        </CartPreviewTop>
        <CartLineItem>
          <Image
            src={
              product.product_id === lastAdded
                ? mainProductImage
                : cross_sells.images[0].file.url
            }
            alt={name}
          ></Image>
          <CartLineItemTitle id={"cartLineItemName"}></CartLineItemTitle>
          <ButtonContainer>
            <CartButton onClick={goToCheckout}>
              <span
                style={{
                  fontFamily: "Work Sans",
                  fontWeight: "900",
                  fontSize: "15px",
                }}
              >
                Checkout
              </span>
            </CartButton>

            <CartLink to={"/cart"}>View Cart</CartLink>
            <CartLink to={"/shop"}>Shop</CartLink>
          </ButtonContainer>
        </CartLineItem>
      </CartPreview>
      <PageContainer>
        <Row>
          <Col md={5} mdOffset={0.5} mdOrder="last" mdAlignSelf="center">
            <Gallery images={images} alt={name} />
          </Col>
          <StyledCol md={6.5}>
            <ProductDetails
              product={product}
              variants={variants?.nodes}
              popCart={popCart}
            />
          </StyledCol>
        </Row>
        {cross_sells && !cross_sell_sold_out ? (
          <Row>
            <StyledCol md={8}>
              <h3 style={{ marginBottom: "0.5em", color: colors.royalBlue }}>
                Complete The Meal
              </h3>
              <Row style={{ paddingBottom: "15px" }}>
                <StyledCol md={5}>
                  <CrossSellImage src={cross_sells.images[0].file.url} />
                </StyledCol>
                <StyledCol md={4}>
                  <a
                    href={"/p/" + cross_sells.slug}
                    style={{ textDecoration: "none" }}
                  >
                    {cross_sells_dish && cross_sells_restaurant ? (
                      <>
                        <CrossSellRestaurantHeader>
                          {cross_sells_restaurant}
                        </CrossSellRestaurantHeader>
                        <CrossSellHeader>{cross_sells_dish}</CrossSellHeader>
                      </>
                    ) : (
                      <CrossSellHeader>{cross_sells.name}</CrossSellHeader>
                    )}
                  </a>
                  <h5 style={{ fontWeight: "bold" }}>
                    ${cross_sells.price.toFixed(2)}
                  </h5>
                  <OptionName>Quantity:</OptionName>
                  <QuantityInput
                    type="number"
                    min="0"
                    value={crossSellQuantity}
                    onChange={(e) => setCrossSellQuantity(e.target.value)}
                  />
                </StyledCol>
              </Row>
              {/* {cross_sells.description} */}

              <AddToCart
                productId={cross_sells.product_id}
                quantity={crossSellQuantity}
                name={cross_sells.name}
                // options={selectedOptions}
                // disabled={error}
                popCart={popCart}
                crossSell={true}
                price={cross_sells.price.toFixed(2)}
              />
            </StyledCol>
            <StyledCol md={6}></StyledCol>
          </Row>
        ) : (
          ""
        )}
        {/* { cross_sells && cross_sells.map((cross_sell, index) => {
          return (<Row key={index}>
            <StyledCol md={6}>
           {JSON.stringify(cross_sell)}
            <AddToCart
                productId={cross_sell.product_id}
                quantity={1}
                // options={selectedOptions}
                // disabled={error}
                popCart={popCart}
              />
   

            </StyledCol>
            <StyledCol md={6}></StyledCol>
          </Row>)
        })
    
        } */}

        {data.contentfulProduct ? (
          <Contentful data={data.contentfulProduct} />
        ) : null}
      </PageContainer>
    </Layout>
  )
}

export default ProductTemplate

export const pageQuery = graphql`
  fragment ProductImage on File {
    childImageSharp {
      gatsbyImageData(
        width: 1200
        height: 1200
        quality: 100
        placeholder: NONE
        transformOptions: { cropFocus: CENTER }
      )
    }
  }

  query ProductTemplateQuery(
    $slug: String!
    $product_id: String!
    $cross_sell_id: String!
  ) {
    product: swellProduct(slug: { eq: $slug }) {
      id
      product_id
      name
      slug
      price
      currency
      slug
      meta_description
      description
      meta_description
      description
      stock_status
      stock_tracking
      stock_purchasable
      sale_price
      sale
      cross_sells {
        product_id
      }
      images {
        file {
          url
        }
        fileLocal {
          ...ProductImage
        }
      }
      options {
        values {
          name
          price
          id
        }
        name
      }
    }
    crossSwellProduct: swellProduct(product_id: { eq: $cross_sell_id }) {
      name
      slug
      description
      product_id
      price
      stock_status
      images {
        file {
          url
        }
        fileLocal {
          ...ProductImage
        }
      }
    }

    variants: allSwellProductVariant(
      filter: { parent_id: { eq: $product_id } }
    ) {
      nodes {
        name
        option_value_ids
        stock_level
        variant_id
        price
      }
    }
    contentfulProduct(slug: { eq: $slug }) {
      title
      dishRatingLink
      socialMediaImage {
        file {
          url
        }
      }
      productDetails {
        __typename
        ... on ContentfulProductDetail {
          title
          content {
            raw
          }
          highlighted
          isDropdown
        }
        ... on ContentfulTextSubBlock {
          heading
          content {
            raw
          }
          ctaLink
          ctaLabel
        }
      }
      blocks {
        __typename
        ... on ContentfulTextImageBlock {
          heading
          media {
            gatsbyImageData(width: 1200)
            description
          }
          content {
            raw
          }
          ctaLabel
          ctaLink
        }
        ... on ContentfulRestaurant {
          address
          description {
            raw
          }
          headChef
          links {
            label
            url
          }
          name
          photo {
            gatsbyImageData(width: 1200)
            description
          }
        }
      }
      ingredients {
        raw
      }
      chefsRecommendations {
        raw
      }
      restaurant {
        address
        description {
          raw
        }
        headChef
        links {
          label
          url
        }
        name
        photo {
          gatsbyImageData
          description
        }
      }
    }
  }
`
