import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import Text from "../../Blocks/Text"
import ExternalLink from "../../Core/ExternalLink"
import { Row, Col } from "styled-bootstrap-grid"
import colors from "../../../styles/colors"
import fonts from "../../../styles/fonts"
import breakpoints from "../../../styles/breakpoints"
import CtaButton from "../../Core/CtaButton"

const BlockContainer = styled.div`
  padding: 17px 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 5vw;
  }
`

const StyledCol = styled(Col)`
  padding: 17px 0;
  text-align: center;
`

const Heading = styled.h2`
  color: ${colors.royalBlue};
`

const Description = styled.div`
  margin-top: 2rem;
`

const ButtonContainer = styled.div`
  margin-top: 2rem;
`

const TextImageBlock = (props) => {
  const { heading, media, content, ctaLabel, ctaLink } = props
  return (
    <BlockContainer>
      <Row mdAlignItems="center">
        <StyledCol md={5}>
          <GatsbyImage
            image={media.gatsbyImageData}
            alt={media.description !== "" ? media.description : heading}
          />
        </StyledCol>
        <StyledCol md={6} mdOffset={1}>
          <Heading>{heading}</Heading>
          <Description>
            <Text content={content} />
          </Description>
          {ctaLink && ctaLabel && (
            <ButtonContainer>
              <CtaButton label={ctaLabel} url={ctaLink} blue />
            </ButtonContainer>
          )}
        </StyledCol>
      </Row>
    </BlockContainer>
  )
}

export default TextImageBlock
