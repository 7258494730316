import React from "react"
import RestaurantBlock from "./RestaurantBlock"
import TextImageBlock from "./TextImageBlock"

const DynamicBlocks = ({ block }) => {
  const { __typename: type } = block

  switch (type) {
    case "ContentfulRestaurant":
      return <RestaurantBlock {...block} />

    case "ContentfulTextImageBlock":
      return <TextImageBlock {...block} />
    default:
      return <div>{type} isn't valid block</div>
  }
}

export default DynamicBlocks
