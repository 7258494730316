import React, { useState, useEffect } from "react"
import styled from "styled-components"
import colors from "../../../styles/colors"
import fonts from "../../../styles/fonts"
import breakpoints from "../../../styles/breakpoints"
import AddToCart from "../AddToCart"
import {
  getLocalisedPrice,
  checkMultiplePrice,
  getGiftCardStartingPrice
} from "../../../services/product/price"
import Select from "react-select"
import { Col, Row } from "styled-bootstrap-grid"
import EmailSubscribe from "./EmailSubscribe"
import { selectStyles } from "src/styles/select"
import PurchasePopup from "../PurchasePopup"
import Purchase from "../Purchase"



const ProductDetailsContainer = styled(Row)``

const Restaurant = styled.h2`
 color: rgb(235, 156, 195);
 font-weight:900;


`
const Name = styled.h1`
  color: ${colors.royalBlue};
  font-size: 2rem;
  line-height: 1.2em;
  margin-bottom: 25px;
  
  @media (min-width: ${breakpoints.lg}) {
    font-size: calc(3 * 1rem);
  }
`

const Price = styled.div`
  ${fonts.workSansBold};
  font-size: calc((1.1 - 1) * 1.2vh + 1rem);
  letter-spacing: 0.01em;
  line-height: 1.2em;
  margin-bottom: 25px;
`

const Description = styled.div`
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  margin-bottom: 40px;
  p {
    margin: 1rem 0;
  }

  em {
    font-style: italic;
  }

  a {
    color: ${colors.royalBlue};
  }
`

const Option = styled.div`
  width: 70%;
  @media (min-width: ${breakpoints.md}) {
    width: 60%;
  }
`

const OptionName = styled.div`
  font-size: calc((0.9 - 1) * 1.2vh + 1rem);
  text-transform: uppercase;
  color: ${colors.black};
  margin-bottom: 0.5em;
`

const Section = styled.div`
  margin-bottom: 40px;
`

const QuantityInput = styled.input`
  ${fonts.workSansRegular};
  border: 1px solid ${colors.midGrey};
  padding: 24px;
  outline: none;
  background: transparent;
  text-align: center;
  font-size: 1rem;
  width: 90px;
`

const SoldOut = styled.div`
  font-size: calc((1 - 1) * 1.2vh + 1rem);
  margin-bottom: 25px;
`
const PurchaseWrapper = styled.div`
  @media (min-width: ${breakpoints.md}) {
    width: 60%;
    button {
      width: 100%;
      :disabled {
        opacity: 0.6;
      }
    }
  }
`

const ProductDetails = ({ product, variants, purchase, popCart}) => {
  const {
    product_id,
    currency,
    sale_price,
    sale,
    price,
    options,
    name,
    stock_status,
    stock_tracking,
    stock_purchasable,
    description,
    restaurant
   
  } = product

  const [selectedOptions, setSelectedOptions] = useState({})
  const [variantPrice, setVariantPrice] = useState( sale ? sale_price : price)
  const [additionalPrice, setAdditionalPrice] = useState({}) //needed to identify added price when option value is selected
  const [selectedOptionIds, setSelectedOptionIds] = useState({}) //needed to identify variant stock level
  const [hasSelectedOption, setHasSelectedOption] = useState(false)
  const [error, setError] = useState(options?.length ? true : false)
  const [variantStock, setVariantStock] = useState(
    stock_status !== "out_of_stock"
  )
  const [quantity, setQuantity] = useState(1)
  const [isPurchaseOpen, setIsPurchaseOpen] = useState(false)
  
  const handleChange = (option, optionName) => {
    const { value, price: optionPrice, id } = option
    let options = selectedOptions
    let priceList = additionalPrice
    let optionIds = selectedOptionIds
    let isVariantInStock = true

    priceList[optionName] = optionPrice
    optionIds[optionName] = id

    const addedPrice = Object.values(priceList).length
      ? Object.values(priceList).reduce((prev, curr) => prev + curr)
      : 0

    const selectedOptionIdValues = Object.values(optionIds)

    let variantPrice = 0
    // let variantSalePrice = 0;

    if (selectedOptionIdValues.length) {
      const selectedVariant = variants.find((variant) => {
        return (
          variant.option_value_ids.sort().join() ===
          selectedOptionIdValues.sort().join()
        )
      })

      if (selectedVariant) {
        if (stock_tracking) {
             isVariantInStock =
               stock_purchasable || selectedVariant.stock_level ? true : false
        }

        if (selectedVariant.price) {
          variantPrice = selectedVariant.price
        }
      }
    }

    if (value) {
      options[optionName] = value
    }

    if (!hasSelectedOption) {
      setHasSelectedOption(true)
    }

    if (Object.keys(options).length < product.options.length) {
      setError(true)
    } else {
      setError(false)
    }

    setSelectedOptions(options)
    if (sale)
    {
      setVariantPrice(variantPrice || sale_price + addedPrice)
    } else
    {
      setVariantPrice(variantPrice || price + addedPrice)
    }
    
    setVariantStock(isVariantInStock)
    setSelectedOptionIds(optionIds)
    setAdditionalPrice(priceList)
  }

  const hasMultiplePrice = checkMultiplePrice(options, variants, price )
  const variantsOptions = options.filter(x=>x.name === "Variants")
  // console.log('variants:', variantsOptions)
  let variantsOptionsValue = null
  if (variantsOptions[0] && variantsOptions[0].values)
  {
    variantsOptionsValue = variantsOptions[0].values.filter(y=>y.name===selectedOptions.Variants)
  }
  // console.log('variantsOptionsValue:', variantsOptionsValue)
  return (
    
    <ProductDetailsContainer>
    
      <Col>
      { restaurant && restaurant.name ? <Restaurant>{restaurant.name}</Restaurant> : "" }
        <Name>{name}</Name>
        <Price>
          {hasMultiplePrice && !hasSelectedOption && "from "}
          {product.slug === "gift-card" && !hasSelectedOption ? (
            <span>{getLocalisedPrice(getGiftCardStartingPrice(options), currency, false)}
            </span>
          ) :
           (
            <span>{getLocalisedPrice(  variantPrice , currency, false)}</span>
          )
          
          }
        </Price>
      </Col>
      <Col smOrder="last" xsOrder="last">
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </Col>
      <Col mdOrder="last">
        {stock_status === "out_of_stock" && <SoldOut>Sold out</SoldOut>}
        {options?.map((option, index) => {
          const { name, values } = option

          const optionValues = values.map((value) => {
            const valueData = {
              label: value.name,
              value: value.name,
              id: value.id,
              price: value.price ? value.price : 0,
            }
            return valueData
          })

          return (
            <Section key={index}>
              <Option>
                <OptionName>{name}:</OptionName>
                <Select
                  placeholder={`Select ${name}`}
                  options={optionValues}
                  styles={selectStyles()}
                  isSearchable={false}
                  onChange={(option) => handleChange(option, name)}
                  // isOptionDisabled={(option) => option.disabled}
                />
              </Option>
            </Section>
          )
        })}
        <Section>
          <OptionName>Quantity:</OptionName>
          <QuantityInput
            type="number"
            min="0"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </Section>
        <Section>
          {variantStock ? (
            purchase ? (
              <Purchase
                productId={product_id}
                quantity={quantity}
                options={selectedOptions}
                disabled={error}
                setIsPurchaseOpen={setIsPurchaseOpen}
              />
            ) : (
              <>
              <AddToCart
                productId={product_id}
                quantity={quantity}
                options={selectedOptions}
                disabled={error}
                popCart={popCart}
                sku={product.sku}
                price={parseInt(getLocalisedPrice(  variantPrice , currency, false).toString().slice(1))}
                variant={ variantsOptionsValue && variantsOptionsValue[0] ?  variantsOptionsValue[0] : null}
                name={product.name}
              />
              {/* { console.log(JSON.stringify(product)) } */}
              </>
            )
          ) : (
            <>
            {/* {console.log('hello '+JSON.stringify(variantsOptionsValue))} */}
            <EmailSubscribe product_id={product_id} option_value_id={selectedOptionIds['Meal Option']} />
            </>
          )}
        </Section>
      </Col>
      {purchase && (
        <PurchasePopup
          isOpen={isPurchaseOpen}
          handlePopup={setIsPurchaseOpen}
        />
      )}
    </ProductDetailsContainer>
  )
}

export default ProductDetails
