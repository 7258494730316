/* global fbq */
import React, { useContext, useState, useEffect } from "react"
import styled from "styled-components"
import { addItem, getCart } from "../../services/cart/cart"
import { CartContext } from "../../context/CartContext"
import OnClickButton from "src/components/Core/OnClickButton"
import breakpoints from "src/styles/breakpoints"
import { useUserContext } from "../../context/UserContext"
import { getUser } from "../../services/user/user"
const axios = require("axios")

const ButtonContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    width: 60%;
    button {
      width: 100%;
      :disabled {
        opacity: 0.6;
      }
    }
  }
`

const AddToCart = ({
  productId,
  options,
  quantity,
  disabled,
  error,
  popCart,
  crossSell,
  sku,
  price,
  variant,
  name
}) => {
  const { user, setUser } = useUserContext()
  const [isAdding, setIsAdding] = useState(false)
  const [cart, setCart] = useContext(CartContext)
  const [showError, setShowError] = useState(false)
  const variant_id = variant ? variant.id : null;
  let id;
  if (variant_id){
    id = variant_id;
  } else {
    id = productId
  }
  const add = async (e) => {
    e.preventDefault()

    setIsAdding(true)

    await addItem(productId, quantity, options)

    const cartData = await getCart()

    let old_gtag_data = {
      event_category: "ecommerce",
      event_label: "add to cart",
      product_id: productId,
      quantity: quantity,
      options: options,
      send_to: "UA-185335499-1"
    }
    if (crossSell) {
      old_gtag_data.event_label = "add cross-sell"
    }
    function generateEventId() {
      return Math.floor(Math.random() * 1e16)
    }
    let formattedValue = parseFloat((price * quantity).toFixed(2))
    const eventId = generateEventId()
    if (typeof fbq === "function") {
      fbq("track", "AddToCart", {
        contents: [{ id: id, quantity: quantity}],
        content_ids: [id],
        content_type: "product",
        value: formattedValue,
        num_items: quantity,
        currency: "USD",
      },{eventID: eventId})
    } else {
      console.log("fbq not defined")
    }
    
    let conversionURL = "https://eo4h5c5cv0xdqc2.m.pipedream.net" //"/.netlify/functions/metaConversion"
    let path = window.location.pathname.substr(1)

    let event_source_url = window.location.href;
    let conversionData = {
      event_source_url,
      event_name: "AddToCart",
      content_ids: [id],
      contents: [{id, quantity}],
      content_type: "product",
      value: formattedValue,
      num_items: quantity,
      currency: "USD",
      event_id: eventId,
    }

    // let conversionData = {
    //   path,
    //   event_name: "AddToCart",
    //   content_ids: [productId],
    //   content_type: "product",
    //   value: formattedValue,
    //   num_items: quantity,
    //   currency: "USD",
    //   event_id: eventId,
    // }

    if (user) {
      conversionData.first_name = user.first_name
      conversionData.last_name = user.last_name
      conversionData.email = user.email
      if (user.shipping) {
        conversionData.phone = user.shipping.phone
        conversionData.zip = user.shipping.zip
      }
    }
    try {
      const conversionResponse = await axios.post(
        conversionURL,
        conversionData,
        // {
        //   headers: {
        //     "client-ip": "127.0.0.1",
        //     "x-forwarded-for": "127.0.0.1",
        //     "x-real-ip": "127.0.0.1",
        //   },
        // }
      ).catch((e) => {
        console.error('Error sending meta conversion data: ' + e)
      })
      console.log(conversionResponse)
    } catch (e) {
      console.log(e)
    }
    if (typeof window.gtag !== "undefined") {
      if (user){
        window.gtag('set', 'user_id', user.id);
      }
      let gtagData = {
        items: [
          {
            item_id: id,
            item_name: name,
            quantity: quantity,
            price: price
          }
        ],
        send_to: "G-LZ50YRTV2Y"

      }
      window.gtag("event", "add_to_cart", gtagData)
      window.gtag("event", "add_to_cart", old_gtag_data);

    }

    setCart(cartData)

    setIsAdding(false)
    popCart(productId, quantity, options)
  }

  return (
    <ButtonContainer>
      <OnClickButton onClick={(e) => add(e)} disabled={disabled || error}>
        {isAdding ? "Adding..." : "Add to order"}
      </OnClickButton>
    </ButtonContainer>
  )
}

export default AddToCart
