const CURRENCY_CODE_USD = "USD"
const CURRENCY_CODE_GBP = "GBP"
const CURRENCY_CODE_EUR = "EUR"
const CURRENCY_SIGN_USD = "$"
const CURRENCY_SIGN_GBP = "£"
const CURRENCY_SIGN_EUR = "€"

let currencySymbols = {}

currencySymbols[CURRENCY_CODE_USD] = CURRENCY_SIGN_USD
currencySymbols[CURRENCY_CODE_GBP] = CURRENCY_SIGN_GBP
currencySymbols[CURRENCY_CODE_EUR] = CURRENCY_SIGN_EUR

function getCurrencySymbol(currencyCode) {
  const currencySymbol = currencySymbols[currencyCode]

  if (currencySymbol) {
    return currencySymbol
  }

  return null
}

function formatPrice(price) {
  let parts = parseFloat(price).toFixed(0).toString().split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  return parts.join(".")
}

function getLocalisedPrice(price, currencyCode, round = true) {
  let priceString
  const currencySymbol = getCurrencySymbol(currencyCode)

  if (!price) {
    price = 0
  }

  if (round) {
    price = Math.ceil(price)
  } else {
    price = price.toFixed(2)
  }

  price = formatPrice(price)

  if (currencyCode === CURRENCY_CODE_EUR) {
    priceString = price + currencySymbol
  } else {
    priceString = currencySymbol + price
  }

  return priceString
}

function checkMultiplePrice(options, variants, price) {
  let hasMultiplePrice = false

  if (options) {
    options.forEach(({ values }) => {
      values.forEach((value) => {
        if (value.price) {
          hasMultiplePrice = true
        }
      })
    })
  }

  if (!hasMultiplePrice && variants) {
    variants.forEach((variant) => {
      if (variant.price) {
        hasMultiplePrice = true;
      }
    })

  }

  return hasMultiplePrice;
}

function getGiftCardStartingPrice(options) {
  const valueOption = options.find((option) => option.name === "Value")
  let startingPrice = 0
  if (valueOption) {
    const values = valueOption.values

    if (values.length > 0) {
      startingPrice = values[0].price
    }
  }

  return startingPrice
}

module.exports = {
  getLocalisedPrice,
  checkMultiplePrice,
  getGiftCardStartingPrice
}
