import React from "react"
import styled from "styled-components"
import colors from "../../../styles/colors"
import { Col, Row } from "styled-bootstrap-grid"
import TextInformation from "./TextInformation"
import Dropdown from "./Dropdown"
import TextBlock from "./TextBlock"

const InformationsContainer = styled.div`
  border-top: 1px solid ${colors.royalBlue};
  border-bottom: 1px solid ${colors.royalBlue};
  margin: 25px 0;
`

const StyledRow = styled(Row)`
  width: calc(100% + 36px);
  margin-left: -17px; //overlap padding value
  padding: 25px 0;
`

const ProductInformation = ({ informations, ingredients, chefsRecommendations }) => {
  const textBlock = informations.find(
    (block) => block.__typename === "ContentfulTextSubBlock",
  )
  const groupedInformations = informations.reduce((prev, curr) => {
    const data = prev

    if (curr.__typename === "ContentfulTextSubBlock") {
      return data
    }

    if (curr.highlighted) {
      data.highlight = curr
    } else {
      if (Object.keys(data).includes("groups")) {
        data.groups.push(curr)
      } else {
        data.groups = [curr]
      }
    }

    return data
  }, {})

  return (
    <InformationsContainer>
      <StyledRow>
        <Col md={Object.keys.length >= 1 || ingredients ? 7 : 12}>
          {chefsRecommendations && <TextInformation content={chefsRecommendations} title={"Chef's recommendations"} />}
          {Object.keys(groupedInformations).map((key, index) => {
            const array = Object.keys(groupedInformations)
            if (key === "highlight") {
              return (
                <TextInformation {...groupedInformations[key]} />
              )
            }
          })}
        </Col>
        <Col md={5}>
          {Object.keys(groupedInformations).map((key, index) => {
            return (
              <>
                {(groupedInformations
                  && groupedInformations[key]
                  && typeof groupedInformations[key] !== "undefined"
                  && groupedInformations[key].length > 0)
                && groupedInformations[key].map((item, i) => {
                  return item.isDropdown ? (
                    <Dropdown {...item} key={i} />
                  ) : (
                    <TextInformation {...item} key={i} />
                  )
                })}
              </>
            )
          })}
          {ingredients && <Dropdown content={ingredients} title={"Ingredients"} />}
        </Col>
      </StyledRow>
      {textBlock && <TextBlock {...textBlock} />}
    </InformationsContainer>
  )
}

export default ProductInformation
