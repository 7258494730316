import React, { useState } from "react"
import styled from "styled-components"
import colors from "../../../styles/colors"
import breakpoints from "../../../styles/breakpoints"
import OnClickButton from "src/components/Core/OnClickButton"
import NewOnClickButton from "src/components/Core/NewOnClickButton"
import axios from "axios"
import { useUserContext } from "src/context/UserContext"


const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const Heading = styled.div`
  min-width: 100%;
  margin-bottom: 1rem;
`

const InputContainer = styled.div`
  padding: 0.5rem 0 0.5rem 0.25rem;
  min-width: 18rem;
  width: 100%;
  display: inline-block;
  input {
    width: 100%;
    padding: 1.4rem 2rem;
    line-height: 1.2rem;
    font-size: calc((1 - 1) * 1.2vh + 1rem);
    border: 1px solid rgba(0, 0, 0, 0.12);

    :focus {
      outline: none;
      border-color: ${colors.midGrey};
    }

    ::placeholder {
      color: rgba(0, 0, 0, 0.3);
      letter-spacing: 0.03em;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 70%;
    padding-right: 1rem;
  }
`

const ButtonContainer = styled.div`
  margin-top: 1rem;
  min-width: 100%;
  @media (min-width: ${breakpoints.lg}) {
    min-width: unset;
    width: 30%;
    margin-top: 0;
  }
`

const Checkbox = styled.label`
  padding-left: 28px;
  padding-right: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  font-size: 0.8rem;
  line-height: 1;
  color: ${colors.midGrey};

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: calc(50% -8px);
    left: 4px;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background-color: "transparent";
    border: 1px solid ${colors.royalBlue};
    cursor: pointer;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: calc(50% - 5px);
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
    background-color: ${colors.royalBlue};
  }
`

const EmailSubscribe = ({ product_id, option_value_id }) => {
  const { user, setUser } = useUserContext()
  const [email, setEmail] = useState()
  const [emailConsent, setEmailConsent] = useState(true)
  const [error, setError] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()

    // const url = "/.netlify/functions/pdpNotification"

    // const data = {
    //   email: email,
    //   product: undefined,
    // }

    // try {
    //   const response = await axios.post(url, data)
    // } catch {
    //   setError("Server error")
    // }

    const newsletterUrl = "/.netlify/functions/newsletter"

      const newsletterData = {
        email: user ? user.email : email,
        referred_from: "Product Waitlist",
        product_id: product_id,
        option_value_id: option_value_id,
        account_id: user?.id,
        emailConsent
      }

      try {
        const response = await axios.post(newsletterUrl, newsletterData)
        
        setEmail("")
        setSubmitted(true)
      } catch {
        setError("Error subscribing to newsletter")
      }
    
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Heading>
       {submitted ? "Thanks! We'll let you know when this meal is back in stock." : "This meal is fully sold out. Get on the list to be the first to know when more meals become available!"}
      </Heading>
  
        {user ? (

          <input style={{ display: "none" }} value={user.email}></input>
        ) : (
          <InputContainer>
          <input
            type="email"
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
              </InputContainer>
        )}
        <Checkbox>
        <input
          type="checkbox"
          name="mailing-list"
          checked={emailConsent}
          onChange={() => setEmailConsent(!emailConsent)}
        />
        <span className="checkmark" />
        Subscribe to our mailing list
      </Checkbox>
      <ButtonContainer>
        <NewOnClickButton type="submit" blue>
          Notify Me
        </NewOnClickButton>
      </ButtonContainer>


    </StyledForm>
  )
}

export default EmailSubscribe
