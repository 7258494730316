import React, { useState } from "react"
import styled from "styled-components"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import Text from "../../Blocks/SmallText"

const DropdownContainer = styled.div`
  padding: 17px;
`

const Title = styled.h4`
  color: ${colors.royalBlue};
  font-size: calc((1.1 - 1) * 1.2vh + 1rem);
  cursor: pointer;
`

const Drawer = styled.div`
  margin-top: 2rem;
  display: ${(props) => (props.open ? "block" : "none")};
`

const Dropdown = (props) => {
  const [open, setOpen] = useState(false)
  const { title, content } = props
  return (
    <DropdownContainer>
      <Title onClick={() => setOpen(!open)}>
        {open ? "- " : "+ "}
        {title}
      </Title>
      <Drawer open={open}>
        <Text content={content} />
      </Drawer>
    </DropdownContainer>
  )
}

export default Dropdown
