import React, { useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick/lib"
import styled from "styled-components"

const GalleryContainer = styled.div`
  position: relative;
  padding-top: 30px;
`

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.next ? "right: 10px" : "left: 10px")};
  cursor: pointer;
  ::after {
    content: "";
    display: block;
    width: 2vw;
    height: 2vw;
    border-style: solid;
    border-color: #000;
    border-width: 1px 1px 0 0;
    margin: calc((calc(2vw + 20px) - 2vw) / 2);
    transform: ${(props) => (props.next ? "rotate(45deg)" : "rotate(225deg)")};
  }
`

const StyledSlider = styled(Slider)`
  .slick-dots {
    position: relative;
    li {
      width: 50px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`

const Gallery = ({ images, alt }) => {
  const sliderRef = useRef()
  const settings = {
    arrows: false,
    dots: true,
    dotsClass: "slick-dots",
    customPaging: function (i) {
      return (
        <a>
          <img src={images[i].file.url} alt={alt} />
        </a>
      )
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
        },
      },
    ],
  }

  const changeSlide = (value) => {
    if (value === -1) {
      sliderRef.current?.slickPrev()
    }
    if (value === 1) {
      sliderRef.current?.slickNext()
    }
  }

  return (
    <GalleryContainer>
      <StyledSlider {...settings} ref={sliderRef}>
        {images && images.map((image, index) => {
          return (
            <GatsbyImage
              image={image.fileLocal?.childImageSharp.gatsbyImageData}
              key={index}
              alt={alt}
            />
          )
        })}
      </StyledSlider>
      {images && images.length > 1 && (
        <>
          <Arrow onClick={() => changeSlide(-1)} />
          <Arrow onClick={() => changeSlide(1)} next />
        </>
      )}
    </GalleryContainer>
  )
}

export default Gallery
