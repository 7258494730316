import React, { useContext, useEffect, useState } from "react"
import axios from "axios"
import styled from "styled-components"
import ReactPopup from "reactjs-popup"
import { ReactComponent as CloseIcon } from "../../resources/img/svg/close.svg"
import {
  AccountButtonDark,
  PopupCloseBtn,
  PopupFormContainer,
  PopupHeaderRow,
  PopupTitle,
} from "../AccountContent/AccountStyles"
import { CartContext } from "../../context/CartContext"
import { getCards } from "../../services/user/user"
import Loader from "../Core/Loader"
import AccountSelectField from "../AccountContent/AccountSelectField"
import breakpoints from "../../styles/breakpoints"
import Select from "react-select"
import { selectStyles } from "src/styles/select"
import { validateEmail } from "../../services/account/popupFormHelper"
import { updateCartData, submitOrder } from "../../services/cart/cart"
import { useUserContext } from "../../context/UserContext"
import { updateAllItems, getCart } from "../../services/cart/cart"
import { inputStyle } from "../Forms/Styles"

const contentStyle = {
  maxWidth: "32rem",
  maxHeight: "80vh",
  margin: "auto",
  background: "#F8F5F3",
  boxSizing: "border-box",
  overflow: "auto",
  borderRadius: "0.25rem",
  border: "none",
}

const contentStyleMobile = {
  ...contentStyle,
  width: "100%",
  margin: "auto",
  height: "auto",
  overflow: "auto",
  background: "#faf8f5",
  overflowY: "scroll",
}

const overlayStyle = {
  overflowY: "auto",
  background: "rgba(28, 26, 23, 0.5)",
}

const IconWrapper = styled.div`
  color: inherit;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`
const LoaderWrapper = styled.div`
  width: 44px;
  height: 44px;
  margin: 20px auto;
`

const PopupContent = styled.div`
  width: 100%;
  @media (min-width: ${breakpoints.sm}) {
    width: unset;
    min-width: 450px;
  }
`

const SelectWrapper = styled.div`
  margin: 10px 0 40px;
`

const TargetButtonsContainer = styled.div`
  margin-top: 0;
  margin-bottom: 11px;
  position: relative;
`

const SlideContainer = styled.div`
  background-color: rgb(242, 242, 242);
  border-color: rgb(242, 242, 242);
  border-radius: 100px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  height: 36px;
  position: relative;
  width: 100%;
`
const SelectionIndicatior = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 100px;
  box-sizing: border-box;
  height: 32px;
  position: absolute;
  top: 0px;
  z-index: 1;
  box-shadow: rgb(0 0 0 / 4%) 0px 0px 1px 0px, rgb(0 0 0 / 4%) 0px 2px 4px 0px;
  transition: left 0.15s cubic-bezier(0.32, 0.94, 0.6, 1) 0s,
    width 0.15s cubic-bezier(0.32, 0.94, 0.6, 1) 0s;
  width: 50%;
  left: ${(props) => (props.right ? "50%" : "0%")};
`
const TargetButtons = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 32px;
  position: relative;
  z-index: 2;
  outline: none;
  flex-direction: row;
  justify-content: space-around;
`

const TargetBtn = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 100px;
  color: rgb(110, 110, 110);
  color: ${(props) => (props.selected ? "#0e0e0e" : "#6e6e6e")};
  display: flex;
  flex: 1 1 50%;
  font-weight: 500;
  height: inherit;
  justify-content: center;
  padding: 0px;
  text-align: center;
  box-sizing: border-box;
  text-rendering: optimizelegibility;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
`

const InputWrapper = styled.div`
  margin: 10px 0 30px;
`

const Input = styled.input`
  ${inputStyle}
`

const PurchasePopup = ({ isOpen, handlePopup }) => {
  const [width, setWidth] = useState(0)
  const { cart } = useContext(CartContext)
  const [cards, setCards] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedCard, setSelectedCard] = useState("")
  const [emailToOther, setEmailToOther] = useState(false)
  const [recipientEmail, setRecepientEmail] = useState("")
  const [isSumbitting, setIsSubmitting] = useState(false)
  const [formError, setFormError] = useState(null)
  const { user } = useUserContext()

  useEffect(() => {
    setLoading(true)
    getCards()
      .then((data) => {
        setCards(data.results)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setLoading(false)
      })
  }, [cart])

  useEffect(() => {
    const updateWindowDimensions = () => {
      if (typeof window !== "undefined") {
        setWidth(window.innerWidth)
      }
    }
    updateWindowDimensions()
    window.addEventListener("resize", updateWindowDimensions)
    return () => {
      window.addEventListener("resize", updateWindowDimensions)
    }
  })

  let cardOptions = []
  if (cards && cards.length > 0) {
    cardOptions = cards.map((card) => ({
      value: card.token,
      label: `···· ···· ···· ${card.last4}`,
    }))
  }

  const handleChange = (value) => {
    setSelectedCard(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    setFormError(null)

    if (!selectedCard) {
      setFormError("Please select a card.")
      return
    }

    if (emailToOther) {
      if (recipientEmail.trim().length === 0) {
        setFormError("Please enter an email address.")
        return
      }
      const validEmail = validateEmail(recipientEmail)
      if (!validEmail) {
        setFormError("Please enter a valid email address.")
        return
      }
    }

    try {
      let hasBilling = true
      let hasMetaData = true
      const cart = await getCart()
      if (cart?.billing?.card?.token === selectedCard) {
        hasBilling = false
      }
      if (cart?.metadata?.email) {
        if (emailToOther) {
          if (cart.metadata.email === recipientEmail) {
            hasMetaData = false
          }
        } else {
          if (cart.metadata.email === user.email) {
            hasMetaData = false
          }
        }
      }

      const card = cards.filter(
        (currentCard) => currentCard.token === selectedCard
      )[0]
      const { token, exp_month, exp_year, brand } = card
      const billing = {
        ...user.billing,
        card: { token, exp_month, exp_year, brand },
      }

      let data = {}

      if (hasMetaData) {
        data.metadata = {
          email: emailToOther ? recipientEmail : user.email,
        }
      }

      if (hasBilling) {
        data.billing = billing
      }
      if (data && Object.keys(data).length !== 0) {
        await updateCartData(data)
      }

      const order = await submitOrder()

      const url = "/.netlify/functions/createGiftCard"

      const giftCardData = {
        orderId: order.id,
        purchaserEmail: order.account.email,
      }

      const response = await axios.post(url, giftCardData)
      if (response?.status === 200) {
        setIsSubmitting(false)
        handlePopup(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleClose = async () => {
    const res = await updateAllItems([])
    const cart = await getCart()
    if (cart?.metadata && Object.keys(cart.metadata).length !== 0) {
      const res = await updateCartData({ metadata: { email: null } })
    }

    handlePopup(false)
  }

  return (
    <ReactPopup
      open={isOpen}
      onClose={handleClose}
      closeOnDocumentClick
      contentStyle={width >= 720 ? contentStyle : contentStyleMobile}
      overlayStyle={overlayStyle}
    >
      <PopupContent>
        <PopupFormContainer>
          <PopupHeaderRow>
            <PopupTitle>Select Card</PopupTitle>
            <PopupCloseBtn onClick={handleClose}>
              <IconWrapper>
                <CloseIcon />
              </IconWrapper>
            </PopupCloseBtn>
          </PopupHeaderRow>
          {loading ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <div>
              {formError && <div>{formError}</div>}
              <form onSubmit={handleSubmit}>
                {cards.length > 0 && (
                  <SelectWrapper>
                    <Select
                      placeholder="Select a card"
                      options={cardOptions}
                      styles={selectStyles()}
                      isSearchable={false}
                      onChange={(e) => handleChange(e.value)}
                    />
                  </SelectWrapper>
                )}
                <TargetButtonsContainer>
                  <SlideContainer>
                    <SelectionIndicatior
                      right={!emailToOther}
                    ></SelectionIndicatior>
                    <TargetButtons>
                      <TargetBtn
                        type="button"
                        onClick={() => setEmailToOther(true)}
                        selected={emailToOther}
                      >
                        Email to Recipient
                      </TargetBtn>
                      <TargetBtn
                        type="button"
                        onClick={() => setEmailToOther(false)}
                        selected={!emailToOther}
                      >
                        Email to You
                      </TargetBtn>
                    </TargetButtons>
                  </SlideContainer>
                </TargetButtonsContainer>
                {emailToOther && (
                  <InputWrapper>
                    <Input
                      type="text"
                      name="recepientEmail"
                      value={recipientEmail}
                      onChange={(e) => setRecepientEmail(e.target.value)}
                      placeholder="Recepient Email"
                    />
                  </InputWrapper>
                )}
                <AccountButtonDark type="submit">
                  {isSumbitting ? "Purchasing" : "Purchase Now"}
                </AccountButtonDark>
              </form>
            </div>
          )}
        </PopupFormContainer>
      </PopupContent>
    </ReactPopup>
  )
}

export default PurchasePopup
